.aboutContainer {
    padding: 160px 0px 60px;
}

.aboutSection {
    padding: 10px;
    background-color: #f4f4f4;
    /* Light background color */
    border-radius: 8px;
    /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    width: 90%;
    max-width: none;
    /* Maximum width for readability */
    margin: 0 auto;
    /* Center horizontally with margin */
    text-align: center;
    /* Center text alignment */
    padding-bottom: 40px;
}

/* Heading style */
.aboutSection h1 {
    font-size: 24px;
    /* Larger font size for headings */
    color: #333;
    /* Dark color for better readability */
    margin-bottom: 20px;
    /* Space below heading */
}

/* Paragraph style */
.aboutSection p {
    font-size: 16px;
    /* Set font size for the paragraph */
    color: #555;
    /* Slightly lighter color for the text */
    line-height: 1.6;
    /* Increase line height for readability */
    margin: 0;
    /* Remove default margins */
}

/* Emphasize call to action */
.aboutSection .cta {
    font-weight: bold;
    /* Make call to action text bold */
    color: #555;
    /* Highlight color for call to action */
    margin-top: 20px;
    /* Space above call to action */
}

.aboutHeader {
    font-size: 24px;
    /* Larger font size for headings */
    color: #333;
    /* Dark color for better readability */
    margin-bottom: 20px;
    /* Space below heading */
}

/* Desktop styles */
@media (min-width: 721px) {

    .aboutContainer {
        margin: 5%;
    }


    .aboutHeader {
        font-size: 45px;
    }

    .aboutSection p {
        font-size: 24px;
    }

    .aboutSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .aboutSection .cta {
        width: 50%;
        word-wrap: break-word;
        /* Allow words to break and wrap */
        overflow-wrap: break-word;
        /* Ensure long words or URLs wrap to the next line */
        white-space: normal;
        /* Ensure that text wraps to the next line normally */
    }
}

/* Add this to your CSS */
@media (min-width: 0px) {
    .trigger-layout-update {
        display: block;
    }
}