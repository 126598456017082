/* Styles for the section */
.section {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-bottom: 10%;
}

/* Styles for the images container */
.images {
    display: flex;
    flex-direction: column;
    /* Stack images vertically on smaller screens */
    justify-content: center;
    /* Center images horizontally */
    align-items: center;
    position: relative;
    width: 100%;
    /* Full width on smaller screens */
    height: 350px;
    /* Allow height to adjust based on content */
    margin: auto;
}

.heading {
    font-size: 2rem;
    /* Large font size */
    margin-bottom: 20px;
    /* Space between heading and video */
    text-align: center;
    /* Center the text */
}

.iframeVideo {
    width: 100%;
    height: 450px;
    /* Adjust height as needed */
    max-width: 800px;
    /* Optional: limit the width on larger screens */
}

.videoContainer {
    padding: 56.25% 0 0 0;
    position: relative;
    width: 100%;
    object-fit: cover;
    top: 30px;
}

.iframeVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Base styles for images */
.image {
    position: absolute;
    /* Remove absolute positioning */
    z-index: 1;
    border-radius: 8px;
    /* Add margin between images */
    max-width: 100%;
    /* Ensure images fit within the container */
    height: auto;
    /* Maintain aspect ratio */
}

/* Styles for individual images */
.image1 {
    height: auto;
    /* Remove fixed height */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 3;
    transform: none;
    top: 50%;
    /* Start from the top */
    left: 0;
    /* Start from the left */
}

.image2 {
    height: auto;
    /* Remove fixed height */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
    z-index: 2;
    transform: none;
    top: 25%;
    /* Start from the top */
    left: 0;
    /* Start from the left */
}

.image3 {
    height: auto;
    /* Remove fixed height */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    z-index: 1;
    transform: none;
    top: 0;
    /* Start from the top */
    left: 0;
    /* Start from the left */
}

/* Styles for the list */
.listContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center the list horizontally */
    width: 100%;
    /* Full width on smaller screens */
    padding-left: 5%;
    padding-right: 3%;
    /* Remove left padding on smaller screens */
    margin: 0 auto;
    /* Center the list container */
    margin-top: 10%;
}

.list {
    list-style-type: disc;
    padding: 0;
    margin: 0;
    text-align: center;
    display: inline-block;
    max-width: 100%;
    /* Ensure the list fits within the container */
    align-items: center;
}

/* Styles for the link */
.link {
    display: block;
    margin-top: 10px;
    color: #457ca8;
    width: 100%;
    height: auto;
    text-align: center;
    text-decoration: none;
}

.promoLinkContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    /* Adjust as needed */
    width: 100%;
    /* Optional: add a subtle shadow */
    padding: 10px;
    /* Optional: add padding for better spacing */
}

.clickHereButton {
    font-weight: bold;
    /* Make the button text prominent */
    text-decoration: none;
    /* Remove underline */
    color: #83dad4;
    /* Button color */
    padding: 10px 15px;
    /* Padding for the button */
    border: 2px solid #56a19c;
    /* Border to make it look like a button */
    border-radius: 5px;
    /* Rounded corners */
    transition: background-color 0.3s, color 0.3s;
    /* Smooth transitions */
    background-color: #fff;
}

.clickHereButton:hover {
    background-color: #457ca8;
    /* Background color on hover */
    color: white;
    /* Text color on hover */
}

/* Desktop styles */
@media (min-width: 721px) {

    /* Styles for the section */
    .section {
        padding: 20px;
        display: flex;
        flex-direction: column;
        /* Align heading on top */
        align-items: center;
        /* Center heading */
        justify-content: center;
        width: 100%;
    }

    .iframeVideo {
        width: 100%;
        height: 700px;
        /* Adjust height as needed */
        max-width: none;
        /* Optional: limit the width on larger screens */
    }

    /* Styles for the images container */
    .images {
        display: flex;
        justify-content: flex-start;
        /* Align images to the left */
        align-items: flex-start;
        margin-bottom: 20px;
        /* Space between images and list */
        /* Full width for images container */
        position: relative;
        height: 700px;
        width: 50%;
        /* Ensure enough space for images */
        padding-top: 5%;
    }

    /* Base styles for images */
    .image {
        position: absolute;
        z-index: 1;
        border-radius: 8px;
        /* Optional: Rounded corners for images */
    }

    /* Styles for individual images */
    .image1 {
        transform: translate(0%, 25%);
        height: 300px;
        /* Adjust height as needed */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        /* Box shadow for image 1 */
        z-index: 3;
        /* Higher z-index */
    }

    .image2 {
        transform: translate(25%, 25%);
        height: 300px;
        /* Adjust height as needed */
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
        /* Box shadow for image 2 */
        z-index: 2;
        /* Medium z-index */
    }

    .image3 {
        transform: translate(0, 0);
        height: 300px;
        /* Adjust height as needed */
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
        /* Box shadow for image 3 */
        z-index: 1;
        /* Lower z-index */
    }

    /* Styles for the list */
    .listContainer {
        display: flex;
        flex-direction: column;
        /* Stack list and promo video vertically */
        align-items: flex-start;
        /* Align to the left */
        width: 50%;
        /* Full width for list container */
        margin: auto;
        /* Space between list and promo video */
        position: relative;
        padding-left: 10%;
    }

    .list {
        list-style-type: disc;
        padding: 0;
        margin: 0;
        text-align: left;
        display: inline-block;
        font-size: large;
    }

    /* Styles for the link */
    .link {
        display: block;
        margin-top: 10px;
        color: #007bff;
        text-decoration: none;
    }

    .heading {
        font-size: 5rem;
        /* Large font size */
        margin-bottom: 20px;
        /* Space between heading and video */
        text-align: center;
        /* Center the text */
    }

    /* Container for the text */
}

/* Add this to your CSS */
@media (min-width: 0px) {
    .trigger-layout-update {
        display: block;
    }
}