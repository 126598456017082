/* Styles for the splash screen container */
.splash {
    position: relative;
    width: 100%;
    height: 40vh;
    /* Full viewport height */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
}

.videoContainer {
    padding: 52.73% 0 0 0;
    position: relative;
    width: 100%;
    object-fit: cover;
    top: 30px;
}

.iframeVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.spanOverlayText {
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin: 0 auto;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    font-size: 1.2rem;
    line-height: 1.1;
    top: 40%;
}

/* Styles for the text overlay container */
.textOverlay {
    position: absolute;
    z-index: 10;
    /* Place text above the video */
    display: flex;
    justify-content: flex-end;
    /* Align text to the right */
    width: 90%;
    /* Limit the width to prevent text from stretching too far */
}

/* Styles for the heading text */
.headingSplash {
    font-size: 1.2rem;
    /* Adjust size as needed */
    max-width: 60%;
    /* Limit width to fit text better */
    line-height: 1.1;
    /* Improve line spacing for readability */
    margin: 0;
    /* Remove default margins */
    text-align: right;
    /* Align text to the right */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    /* Adds shadow for better readability */
}

/* Desktop styles */
@media (min-width: 721px) {

    .splash {
        margin-top: 60px;
        position: relative;
        width: 100%;
        height: 100%;
        /* Full viewport height */
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-align: center;
    }

    .videoContainer {
        padding: 52.73% 0 0 0;
        position: relative;
        width: 100%;
        object-fit: cover;
        top: 85px;
    }

    .iframeVideo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .spanOverlayText {
        position: absolute;
        top: 50%;
        width: auto;
        text-align: center;
        margin: 0 auto;
        color: white;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
        font-size: 4.0rem;
        line-height: 1.5;
        top: 50%;
    }

    .textOverlay {
        position: relative;
        z-index: 5;
        /* Place text above the video */
        display: flex;
        justify-content: flex-end;
        /* Align text to the right */
        width: 80%;
        /* Limit the width to prevent text from stretching too far */
    }

    /* Styles for the heading text */
    .headingSplash {
        font-size: 1.7rem;
        /* Adjust size as needed */
        max-width: 60%;
        /* Limit width to fit text better */
        line-height: 1.5;
        /* Improve line spacing for readability */
        margin: 0;
        /* Remove default margins */
        text-align: right;
        /* Align text to the right */
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
        /* Adds shadow for better readability */
    }
}

/* Add this to your CSS */
@media (min-width: 0px) {
    .trigger-layout-update {
        display: block;
    }
}