/* Styles for the contact section */
.contactSection {
    display: flex;
    padding: 20px;
    background-color: #f4f4f4;
    /* Light background color */
    box-sizing: border-box;
    flex-direction: column;
}

/* Styles for the menu */
.menu {
    flex: 1;
    padding-right: 20px;
    border-right: 1px solid #ddd;
    /* Optional: Add a border to separate the menu */
    margin-left: 10%;
    color: #4ebeb0;
}

.menu h3 {
    margin-top: 0;
    margin-bottom: 15px;
    color: #555;
}

.menu ul {
    list-style: none;
    padding: 0;
}

.menu ul li {
    margin-bottom: 10px;
}

.menu ul li a {
    text-decoration: none;
    color: #4ebeb0;
    /* Link color */
}

/* Styles for the contact information */
.contactInfo {
    flex: 2;
    padding-right: 20px;
    border-right: 1px solid #ddd;
    /* Optional: Add a border to separate contact info from the form */
}

.contactInfo h3 {
    margin-top: 0;
    margin-bottom: 15px;
}

.contactInfo p {
    margin: 5px 0;
    /* Space between contact details */
}

/* Styles for the contact form */
.contactForm {
    flex: 2;
    margin-right: 10%;
    margin-left: 10%;
}

.contactForm h3 {
    margin-top: 0;
    margin-bottom: 20px;
}

.contactForm form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 20px;
    /* Space between form elements */
}

.contactForm form label {
    flex: 1 1 calc(50% - 20px);
    /* 2 columns, minus gap */
    margin-bottom: 20px;
}

.contactForm form input[type="text"],
.contactForm form input[type="email"],
.contactForm form input[type="number"],
.contactForm form textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.contactForm form textarea {
    resize: vertical;
    /* Allow vertical resize */
}

.contactButton {
    padding: 10px 20px;
    background-color: #e2cf81;
    /* Button color */
    color: #555;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.contactButton:hover {
    background-color: #a8995f;
    /* Darker shade on hover */
}

/* Desktop styles */
@media (min-width: 721px) {

    /* Styles for the contact section */
    .contactSection {
        display: flex;
        padding: 20px;
        background-color: #f4f4f4;
        /* Light background color */
        box-sizing: border-box;
        flex-direction: row;
    }

    /* Styles for the menu */
    .menu {
        flex: 1;
        padding-right: 20px;
        border-right: 1px solid #ddd;
        /* Optional: Add a border to separate the menu */
        margin-left: 10%;
        z-index: 10;
    }

    .menu h3 {
        margin-top: 0;
        margin-bottom: 15px;
    }

    .menu ul {
        list-style: none;
        padding: 0;
    }

    .menu ul li {
        margin-bottom: 10px;
    }

    .menu ul li a {
        text-decoration: none;
        color: #4ebeb0;
        /* Link color */
    }

    /* Styles for the contact information */
    .contactInfo {
        flex: 2;
        padding-right: 20px;
        border-right: 1px solid #ddd;
        /* Optional: Add a border to separate contact info from the form */
    }

    .contactInfo h3 {
        margin-top: 0;
        margin-bottom: 15px;
    }

    .contactInfo p {
        margin: 5px 0;
        /* Space between contact details */
    }

    /* Styles for the contact form */
    .contactForm {
        flex: 2;
        margin-right: 10%;
        margin-left: 10%;
        z-index: 100;
    }

    .contactForm h3 {
        margin-top: 0;
        margin-bottom: 20px;
    }

    .contactForm form {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 20px;
        /* Space between form elements */
    }

    .contactForm form label {
        flex: 1 1 calc(50% - 20px);
        /* 2 columns, minus gap */
        margin-bottom: 20px;
    }

    .contactForm form input[type="text"],
    .contactForm form input[type="email"],
    .contactForm form input[type="number"],
    .contactForm form textarea {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
    }

    .contactForm form textarea {
        resize: vertical;
        /* Allow vertical resize */
    }

    .contactButton {
        padding: 10px 20px;
        background-color: #e2cf81;
        /* Button color */
        color: #555;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    .contactButton:hover {
        background-color: #a8995f;
        /* Darker shade on hover */
    }
}

/* Add this to your CSS */
@media (min-width: 0px) {
    .trigger-layout-update {
        display: block;
    }
}