/* ScrollArrow.css */

.scroll-arrow {
    position: absolute;
    bottom: 30px;
    /* Position at the bottom of the screen */
    left: 50%;
    transform: translateX(-50%);
    font-size: 35px;
    color: #FFFFFF;
    cursor: pointer;
    animation: bounce 1.5s infinite;
    z-index: 10;
    transition: opacity 0.3s ease-in-out;
}

.scroll-arrow.hidden {
    opacity: 0;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-15px);
    }

    100% {
        transform: translateY(0);
    }
}

@media (min-width: 721px) {

    .scroll-arrow {
        color: #FFFFFF;
    }

}