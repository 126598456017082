.landingContainer {
    box-sizing: border-box;
    background-color: #83dad4;
}


/* Styles for the header */
.header {
    position: fixed;
    width: 100%;
    height: 30px;
    /* Adjust height as needed */
    background-color: #333;
    color: #e2cf81;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    /* Ensure it stays above other content */
}

/* Styles for the contact info container */
.contactInfo {
    display: flex;
    gap: 20px;
}

/* Styles for individual contact items */
.contactItem {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
}

/* Styles for the icons */
.icon {
    font-size: 12px;
}

.navbarr {
    display: flex;
    justify-content: space-between;
    /* Space out logo and hamburger menu */
    align-items: center;
    padding: 10px 20px;
    background-color: #222;
    color: #e2cf81;
    position: fixed;
    top: 29px;
    /* Fixed at the top of the viewport */
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logoContainerStyle {
    display: flex;
    align-items: center;
    margin-right: auto;
}

/* Styles for the logo image */
.logoStyle {
    height: 50px;
    max-width: 100%;
    object-fit: contain;
}

/* Styles for the logo link */
.logoLinkStyle {
    display: block;
    text-decoration: none;
}

.navOptionsStyle {
    display: none;
    /* Hide navigation options by default */
    width: 100%;
    text-align: center;
    position: absolute;
    top: 60px;
    /* Position below the header */
    left: 0;
    background-color: #222;
    z-index: 1000;
    flex-direction: column;
    margin-bottom: 10px;
}

/* Dropdown menu styles */
.dropdownMenu {
    color: #e2cf81;
    display: flex;
    /* Hidden by default */
    position: fixed;
    top: 105px;
    /* Adjust this value based on the height of your navbar */
    right: 0;
    /* Align to the right edge of the viewport */
    background-color: #222;
    z-index: 1000;
    width: 100%;
    /* Full width of the viewport */
    text-align: center;
    flex-direction: row;
    /* Stack items vertically */
    justify-content: center;
}

/* Styles for the unordered list in the navbar */
.ulStyle {
    list-style-type: none;
    display: flex;
    gap: 15px;
    margin: 0;
    padding: 0;
}

/* Styles for the navigation links */
.linkStyle {
    text-decoration: none;
    color: inherit;
    font-size: 12px;
}

.hamburgerMenuStyle {
    display: flex;
    /* Show the hamburger menu on mobile */
    margin: auto;
    flex-direction: column;
}

/* Desktop styles */
@media (min-width: 721px) {

    .navbarr {
        display: flex;
        justify-content: space-between;
        /* Space out logo and hamburger menu */
        align-items: center;
        padding: 10px 20px;
        background-color: #222;
        color: #e2cf81;
        position: fixed;
        top: 59px;
        /* Fixed at the top of the viewport */
        width: 100%;
        z-index: 1000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .logoContainerStyle {
        display: flex;
        align-items: center;
        margin-right: auto;
        margin-left: 15%;
    }

    /* Styles for the logo image */
    .logoStyle {
        height: 100px;
        max-width: 100%;
        object-fit: contain;
    }

    /* Styles for the logo link */
    .logoLinkStyle {
        display: block;
        text-decoration: none;
    }

    .hamburgerMenuStyle {
        display: none;
    }

    .navOptionsStyle {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: auto;
        margin-left: 50%;
    }

    .dropdownMenu {
        color: aqua;
        display: none;
        /* Hidden by default */
        position: absolute;
        top: 130px;
        /* Adjust this value based on the height of your navbar */
        right: 0;
        /* Align to the right edge of the viewport */
        background-color: #222;
        z-index: 1000;
        width: 100%;
        /* Full width of the viewport */
        text-align: center;
        flex-direction: row;
        /* Stack items vertically */
        justify-content: center;
    }

    .header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        /* Adjust height as needed */
        background-color: #333;
        color: #e2cf81;
        display: flex;
        align-items: center;
        padding: 0 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        /* Ensure it stays above other content */
    }

    /* Styles for individual contact items */
    .contactItem {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 18px;
    }

    .icon {
        font-size: 18px;
    }

    /* Styles for the navigation links */
    .linkStyle {
        text-decoration: none;
        color: inherit;
        font-size: 22px;
    }

    .ulStyle {
        list-style-type: none;
        display: flex;
        gap: 30px;
        margin: 0;
        padding: 0;
    }
}

/* Add this to your CSS */
@media (min-width: 0px) {
    .trigger-layout-update {
        display: block;
    }
}