.pricingContainer {
    padding: 160px 0px 60px;
}

/* Styles for the pricing section */
.pricingSection {
    padding: 10px;
    background-color: #f4f4f4;
    /* Light gray background for contrast */
    border-radius: 8px;
    /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    max-width: none;
    /* Maximum width for readability */
    margin: 0 auto;
    /* Center horizontally with margin */
    text-align: center;
    /* Center text alignment */
    width: 90%;
    padding-bottom: 40px;
}

/* Styles for the heading */
.pricingHeading {
    font-size: 28px;
    /* Larger font size for heading */
    color: #333;
    /* Dark color for better readability */
    margin-bottom: 20px;
    /* Space below heading */
}

/* Styles for the paragraph */
.pricingParagraph {
    font-size: 18px;
    /* Set font size for the paragraph */
    color: #555;
    /* Slightly lighter color for text */
    line-height: 1.6;
    /* Increase line height for readability */
    margin: 0;
    /* Remove default margins */
}

/* Mobile styles */
@media (max-width: 720px) {}

/* Desktop styles */
@media (min-width: 721px) {
    .pricingContainer {
        margin: 5%;
    }

    .pricingHeading {
        font-size: 45px;
    }

    .pricingSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .pricingParagraph {
        width: 50%;
        word-wrap: break-word;
        /* Allow words to break and wrap */
        overflow-wrap: break-word;
        /* Ensure long words or URLs wrap to the next line */
        white-space: normal;
        /* Ensure that text wraps to the next line normally */
        font-size: 24px;
    }
}

/* Add this to your CSS */
@media (min-width: 0px) {
    .trigger-layout-update {
        display: block;
    }
}